import React from "react"
import Layout from "../components/Layout/Layout"
import styles from "./social.module.css"
import { FaFacebook, FaPinterest, FaInstagram } from "react-icons/fa"

export default function() {
  return (
    <Layout style={{ backgroundColor: "#b1a3cc" }}>
      <div className={styles.socialHook}>Let's get social</div>
      <div className={styles.socialBtnContainer}>
        <a
          href="https://www.facebook.com/PhyllisMorrisOriginals/"
          className={styles.socialBtn}
        >
          <FaFacebook size="100" color="#fff" />
          <h3 className={styles.username}>@CircaWallCoverings</h3>
        </a>
        <a
          href="https://www.instagram.com/circawallcovering/?hl=en"
          className={styles.socialBtn}
        >
          <FaInstagram size="100" color="#fff" />
          <h3 className={styles.username}>@CircaWallCoverings</h3>
        </a>
        <a
          href="https://www.pinterest.com/phyllis3665/"
          className={styles.socialBtn}
        >
          <FaPinterest size="100" color="#fff" />
          <h3 className={styles.username}>@CircaWallCoverings</h3>
        </a>
      </div>
      <div className={styles.bottomRow}>
        <a
          data-pin-do="embedUser"
          data-pin-board-width="500"
          data-pin-scale-height="240"
          data-pin-scale-width="80"
          href="https://www.pinterest.com/circawallcovering"
        ></a>
      </div>
    </Layout>
  )
}
